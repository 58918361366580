export default {
  multiCall: {
    4: '0xeefBa1e63905eF1D7ACbA5a8513c70307C1cE441',
    1: '0xb0A452DcB9c7cC99bA6a16A0583b8e18e9D3A4c1',
  },
  pyeClaim: {
    4: '',
    1: '0x84068AA1A904Ff5403EFC980e8a8342568eD2844',
  },
}
